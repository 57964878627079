@tailwind base;



input:focus~label {
  @apply relative top-[9px]  rtl:mr-3 ml-3 w-fit rounded-t-xl border-2 bg-white border-b-white px-3 text-center text-xs text-maincolor transition-all ease-in-out;
}

.ui.form input:not([type]):focus,
.ui.form input[type=date]:focus,
.ui.form input[type=datetime-local]:focus,
.ui.form input[type=email]:focus,
.ui.form input[type=file]:focus,
.ui.form input[type=number]:focus,
.ui.form input[type=password]:focus,
.ui.form input[type=search]:focus,
.ui.form input[type=tel]:focus,
.ui.form input[type=text]:focus,
.ui.form input[type=time]:focus,
.ui.form input[type=url]:focus {
  @apply border-border border-2 !important
}