@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
@layer base {
  * {
    font-family: "Cairo", sans-serif;
  }
}

body {
  @apply bg-backgroundGray;
}
/* 
*:lang(en) {
  font-family: "Lato" sans-serif;
}

*:lang(ar) {
  font-family: "Noto Naskh Arabic" serif;
} */

a {
  @apply hover:text-current;
}

/* edit for dropdown */
.ui.dropdown > .dropdown.icon:before {
  @apply hidden;
}
/* ///////////////////////////// */
/* edit for order tab */
.edit-For-order-tab .ui.secondary.menu .item {
  @apply text-maintext px-2 py-2 text-2xl;
}
.edit-For-order-tab .ui.secondary.menu .item {
  @apply hover:bg-maincolor/20 hover:text-maincolor;
}
.edit-For-order-tab .ui.secondary.menu .active.item {
  @apply bg-maincolor/10 text-maincolor;
}
/* //////////////////////////// */
/* ///////////////////////////// */
/* edit for branches tab */
.edit-For-branches-tab .ui.secondary.menu .item {
  @apply text-maintext px-2 py-2 text-2xl;
}
.edit-For-branches-tab .ui.secondary.menu .item {
  @apply hover:bg-maincolor/20 hover:text-maincolor;
}
.edit-For-branches-tab .ui.secondary.menu .active.item {
  @apply bg-maincolor/10 text-maincolor;
}
/* //////////////////////////// */
/* edit for view branches tab */
.edit-For-view-branches-tab .ui.secondary.pointing.menu .item {
  @apply text-subtext px-8 text-lg;
}
.edit-For-branches-tab .ui.secondary.pointing.menu .item {
  @apply hover:text-maincolor;
}
.edit-For-branches-tab .ui.secondary.pointing.menu .active.item {
  @apply text-maincolor border-maincolor bg-transparent;
}
/* //////////////////////////// */
/* carousel */
.carousel .control-dots .dot {
  @apply bg-maincolor  w-4 h-4;
}

ul .slider .animated {
}
/* //////////////////////////// */

/* Map search combobox */
.mapSearch {
  position: absolute;
  top: 1rem;
  left: 45%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  z-index: 2000;
}
.mapSearch input {
  padding: 0.5rem;
  width: 100%;
}

/*----------------------- edit_for_Paginatio--------------------------- */
.edit_for_Pagination > .ui.secondary.menu {
  @apply border-2 border-border  text-border shadow-none flex hover:bg-transparent  !important;
}
.edit_for_Pagination > .ui.secondary.menu .active.item {
  @apply bg-admingreen border-none rounded-md text-white text-lg !important;
}
.edit_for_Pagination > .ui.secondary.menu .item {
  @apply rounded-md  text-lg border-2 border-border  text-border hover:bg-admingreen  outline  p-0.5  mx-2 flex justify-center   !important;
}
.edit_for_Pagination > .ui.pagination.menu .item {
  @apply min-w-[2rem] !important;
}
.edit_for_Pagination > .ui.menu > .item:first-child {
  @apply rounded-md hover:bg-white !important;
}
.edit_for_Pagination > .ui.menu > .item:last-child {
  @apply rounded-md hover:bg-white !important;
}
/* /////////////////////////////////////////////////////////////////// */
.center-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
